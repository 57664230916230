export const getLevelName = id => {
  switch (id) {
    case 1:
      return "Bronze";
    case 2:
      return "Prata";
    case 3:
      return "Ouro";
    default:
      return null;
  }
};
