import React, { useEffect } from "react";
import { useAppState, useQuery } from "../AppContext";
import { fetchLoggedUser } from "../../commons/logged-user/actions";
import { readCertificate } from "../acquire-reliability/certificate/actions";

const componentDidMount = (dispatch, state) => () => {
  if (window.location.hostname.startsWith("certificado.")) {
    var accountId = new URLSearchParams(window.location.search).get(
      "accountId"
    );
    if (accountId !== undefined && accountId !== null) {
      dispatch(readCertificate(accountId));
    }
  }
  dispatch(fetchLoggedUser());
};

function Init() {
  const [state, dispatch] = useAppState();
  const paramObj = useQuery();
  const qpClientId = paramObj.get("client_id");
  if (qpClientId) {
    sessionStorage.setItem("clientUrlReliability", window.location.href);
  }
  useEffect(componentDidMount(dispatch, state), []);
  return !state.logged_user.id && <span className="init__message" />;
}

export default Init;
