import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppState, useQuery } from "../../AppContext";
import { changeInfoTela } from "../../layout/actions";
import fetchApi from "../../../commons/fetch-api";

function AccreditedBanksRedirectEndpoint() {
  const dispatch = useAppState()[1];
  useEffect(() => {
    dispatch(
      changeInfoTela({
        title: "Obter Confiabilidade via Bancos Credenciados",
        breadcrumbs: [{ label: 'Nível da conta', link: '/' }, { label: 'Aumentar nível da conta', link: '/quero-este-nivel?nivel=prata' }, { label: "Obter Confiabilidade via Bancos Credenciados" }]
      })
    );
    let url = new URL(window.location.href);
    url.pathname = "/api" + url.pathname;

    fetchApi(url.toString(), { method: "GET" }).then(
      () => (window.location = "/")
    );
  }, [dispatch]);

  return (
    <>
      <span className="page-title">
        Obter Confiabilidade via Bancos Credenciados
      </span>
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/quero-este-nivel?nivel=prata">
            Voltar
          </Link>
        </div>
      </div>
    </>
  );
}

export default AccreditedBanksRedirectEndpoint;
